import React from 'react'
import Foot from './CityGym/Footer/Foot';

//import './App.css'
import Hero from './CityGym/Hero/Hero';
import Join from './CityGym/Join/Join';
import Plans from './CityGym/Plans/Plans';
import Program from './CityGym/Program/Program';
import Reason from './CityGym/Reasons/Reason';
import Test from './CityGym/Testimonials/Test';
//import Ifelse from './Components/Ifelse';
//import Comp from './Components/Comp'
//import Should from './Components/Should';
//import Unmou from './Components/Unmou';
//import Usest from './Components/Usest'
//import Useef from './Components/Useef';
//import Ustpr from './Components/Ustpr';
//import Styl from './Components/Styl';
//import Maparr from './Components/Maparr';
//import Mapbot from './Components/Mapbot';
//import Nlnar from './Components/Nlnar';
//import Reus from './Components/Reus';
//import Recfr from './Components/Recfr'
//import Lifst from './Components/Lifst'
//import Pure from './Components/Pure';
//import Mem  from './Components/Mem';
//import Reff from './Components/Reff';
//import Useref from './Components/Useref';
//import Forref from './Components/Forref';
//import Conco from './Components/Conco';
//import Unco from './Components/Unco';
//import Hocc from './Components/Hocc';
//import Rout from './Components/Rout';
//import Dynrou from "./Components/Dynrou";
//import Psta from './Components/Psta';
//import Ppro from './Components/Ppro';
//import Ccont from './Components/ContextApi/Ccont';
function App(){
  return (
    <div className='App' >
     {/*<Ifelse/>
      <Comp/>
      <Should />
      <Unmou />
      <Usest />
      <Useef />
      <Ustpr />
      <Styl />
      <Maparr />
      <Mapbot />
      <Nlnar />
      <Reus />
      <Recfr />
      <Lifst />
      <Pure />
      <Mem />
      <Reff />
      <Useref />
      <Forref />
      <Conco />
      <Unco />
      <Hocc />
      <Rout />
      <Dynrou />
      <Psta />
      <Ppro />
      <Ccont />*/}
      <Hero />
      <Program />
      <Reason />
      <Plans />
      <Test />
      <Join />
      <Foot />
    </div>
  )
}

export default App;